
// DONT MODIFY
@import './variables';

@mixin parent-breakpoint($breakpoint) {

  $mediaQuery: map-get($parent-breakpoints, $breakpoint);

  @if ($mediaQuery != null) {

      @media #{$mediaQuery} {
          @content
      }
  } @else {

      @media #{$breakpoint} {
          @content
      }
  }
};

@mixin border-radius ($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin circle($r, $bg:null, $tc:null) {
    width: $r;
    height: $r;
    display: inline-flex;
    justify-content:center;
    align-items:center;
    background-color: $bg;
    color: $tc;
    @include border-radius($r);
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content};
    &:-moz-placeholder           {@content};
    &::-moz-placeholder          {@content};
    &:-ms-input-placeholder      {@content};
}

@mixin no-mp () {
    margin: 0 !important;
    padding: 0 !important;
}
