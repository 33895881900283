
@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Product Sans'), url('../../fonts/ProductSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Product Sans'), url('../../fonts/ProductSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Product Sans'), url('../../fonts/ProductSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Product Sans'), url('../../fonts/ProductSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Product Sans'), url('../../fonts/ProductSans-Black.ttf') format('truetype');
}