@import "/src/assets/scss/abstract/abstract.scss";

.notification-wrapper {
    .list-group-item {
        @extend .ft-default;
        border: none;
        padding: 24px 32px;
        min-width: 350px;
        cursor: pointer;
        &:hover {
            background-color: map-get($cool-gray, 100);
        }
        .name {
            @include circle(62px);
            color: white;
        }
    }
    .setting-card {
        @include parent-breakpoint('lt-lg') {
            height: auto;
            margin-top: 30px;
        }
        .switch-container {
            .form-check-input {
                display: none;
                &:checked {
                    & + .form-check-label {
                        .slider {
                            background-color: map-get($cool-green, 400);
                            &:before {
                                background-color: white;
                                left: 18px;
                            }
                        }
                    }
                }
            }
            .switch-box {
                display: inline-block;
                width: 32px;
                height: 16px;
                position: relative;
                top: 3px;
                margin-right: 10px;
                .slider {
                    position: relative;
                    display: block;
                    border-radius: 30px;
                    background-color: map-get($cool-gray, 500);
                    cursor: pointer;
                    overflow: hidden;
                    transition: .4s;
                    width: 100%;
                    height: 100%;
                    &:before {
                      content: "";
                      position: absolute;
                      top: 2px;
                      left: 2px;
                      width: 12px;
                      height: 12px;
                      background-color: $white;
                      border-radius: 30px;
                      transition: .4s;
                    }
                }
            }
        }
    }
}