

$cool-primary: map-get($parent-colors, 'cool-primary');
$cool-green: map-get($parent-colors, 'cool-green');
$cool-orange: map-get($parent-colors, 'cool-orange');
$cool-red: map-get($parent-colors, 'cool-red');

$gray: map-get($parent-colors, 'cool-gray');

body {
    @extend .ft-default;
    @extend .text-gray-950;
}

ul, h1, h2, h3, h4, h5, h6, p {
    @include no-mp();
}

h1, h2, h3, h4, h5, h6, p {
    font-family: 'Poppins', sans-serif;
}

ul li {
list-style: none;
}

a {
    text-decoration: none;
    font-size: 14px;
}

//layout
.layout-wrapper {
    .main-container {
        width: 100%;
        .content-block {
            flex: 1;
            display: flex;
            min-height: calc(100vh - 110px);
            margin-top: 110px;
            @include parent-breakpoint('lt-lg') {
                min-height: calc(100vh - 80px);
                margin-left: 0;
                margin-top: 80px;
            }
            > div {
                width: 100%;
                padding: 24px;
                @include parent-breakpoint('gt-md') {
                    padding: 38px 58px 38px 58px;
                }
            }
            .title {
                margin-bottom: 30px !important;
            }
        }
    }
    &.bar {
        .content-block {
            @include parent-breakpoint('gt-md') {
                margin-left: 120px;
            }
        }
    }
}

//card
.card {
    border: none;
    @include parent-breakpoint('lt-lg') {
        height: auto !important;
    }
    &.lux {
        color: #000;
        box-shadow: 0px 14px 40px map-get($cool-primary, 100);
        padding: 0 12px;
        @include parent-breakpoint('gt-md') {
            padding: 0 24px;
        }
    }
    &.gray-card {
        box-shadow: 0px 3px 6px rgba(map-get($cool-gray, 900), 0.16);
        .card-header {
            background-color: map-get($cool-gray , 150);
            @extend .ft-default;
            padding: 20px 0 17px 20px;
            color: map-get($cool-gray, 900);
            border-bottom: none;
        }
        .card-body {
            padding: 40px;
        }
    }
    &.table-card {
        @include parent-breakpoint('lt-lg') {
            background: transparent;
        }
        th {
            background-color: map-get($cool-gray , 150);
            color: map-get($cool-gray, 900);
        }
        th, tr td {
            @extend .ft-default;
            @extend .fw-regular;
            @extend .text-base;
            padding: 20px 17px;
        }
        td {
            color: map-get($cool-gray, 700);
        }
        @include parent-breakpoint('lt-lg') {
            thead { display: none; }
            tr {
                display: inline-block;
                border: 0;
                background-color: #fff;
                width: calc(50% - 18px);
                margin-right: 18px;
                margin-bottom: 18px; 
                td {
                    display: block;
                    text-align: center;
                    padding: 8px !important;
                    border-color: map-get($cool-gray , 200);
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        @include parent-breakpoint('xs') {
            tr {
                width: 100%;
            }
        }
    }
}

//modal
.modal {
    .modal-dialog {
        @include parent-breakpoint('lt-lg') {
            max-width: calc(100% - 80px);
            margin-left: auto;
            margin-right: auto;
        }
        @include parent-breakpoint('xs') {
            max-width: calc(100% - 40px);
        }
        .modal-body {
            padding: 0;
            > div {
                padding: 36px 30px;
                @include parent-breakpoint('lt-md') {
                    padding: 36px 24px;
                }
            }
            .title {
                position: relative;
                .close-icon {
                    width: 40px;
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            .content-btn {
                width: 200px;
                @include parent-breakpoint('lt-md') {
                    width: 100%;
                }
            }
        }
    }
}


.simple-upload-wrapper {
    position: relative;
    @include circle(100px);
    .simple-upload-container {
        @include circle(86px);
        border: 3px solid $primary;
        cursor: pointer;
        .simple-upload-box p {
            font-size: 10px;
            padding: 4px 0 0 0 !important;
            @extend .text-gray-900;
        }
        .form-control {
            position: absolute;
            @include circle(86px);
            overflow: hidden;
            z-index: 10;
            opacity: 0;
        }
        .img-preview-holder {
            @include circle(80px);
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .close-icon {
        position: absolute;
        left: 0;
        top: 0;
        @include circle(36px);
        background-color: rgba( map-get($cool-primary, 100), 0.7);
        border: 1px solid map-get($cool-gray, 300);
        color: map-get($cool-gray, 700);
        cursor: pointer;
    }
}

//profile pic upload
.profile-pic-upload-wrapper {
    position: relative;
    .profile-pic-upload {
        position: relative;
        width: 148px;
        height: 148px;
        border-radius: 28px;
        border: 3px solid map-get($cool-primary, 300);
        overflow: hidden;
        .img-placeholder, .user-image, .img-previewholder {
            position: absolute;
        }
        .img-placeholder, .img-previewholder {
            width: 100%; 
            height: 100%; 
            object-fit: cover;
            overflow: hidden;
        }
        .img-placeholder {
            z-index: 1; 
            &.z-0 {
                z-index: 0 !important;
            }
        }
        .img-previewholder {
            z-index: 2; 
        }
        .form-control {
            position: absolute;
            width: inherit;
            height: inherit;
            overflow: hidden;
            z-index: 10;
            opacity: 0;
        }
    }
    .close-icon {
        position: absolute;
        left: -10px;
        top: -10px;
        @include circle(36px);
        background-color: rgba( map-get($cool-primary, 100), 0.7);
        border: 1px solid map-get($cool-gray, 300);
        color: map-get($cool-gray, 700);
        cursor: pointer;
        z-index: 100;
    }
}

.tooltip.bs-tooltip-end, .tooltip.bs-tooltip-start, .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-bottom {
    .tooltip-inner {
      background-color: white;
      @extend .text-gray-700;
      @extend .fw-regular;
      @extend .ft-default;
      @extend .text-normal;
      text-align: left;
      border-radius: 2px;
      padding: 14px 20px;
      box-shadow: 0 1px 12px 10px rgba(map-get($cool-gray, 900),0.1);
    }
}

.tooltip {
    margin-left: 5px;
}

.tooltip.bs-tooltip-start {
    .tooltip-arrow {
        &:before {
            border-top:10px solid transparent;
            border-bottom:10px solid transparent;
            border-left:10px solid white
        }
    }
}

.tooltip.bs-tooltip-end {
    .tooltip-arrow {
        &:before {
            border-top:10px solid transparent;
            border-bottom:10px solid transparent;
            border-right:10px solid white
        }
    }
}

.tooltip.bs-tooltip-top {
    .tooltip-arrow {
        &:before {
            border-left:10px solid transparent;
            border-right:10px solid transparent;
            border-top:10px solid white
        }
    }
}

.tooltip.bs-tooltip-bottom {
    .tooltip-arrow {
        &:before {
            border-left:10px solid transparent;
            border-right:10px solid transparent;
            border-bottom:10px solid white
        }
    }
}

//datepicker
.react-datepicker {
    @extend .ft-default;
    @extend .text-sm;
    &, .react-datepicker__header {
        border-color: darken(map-get($cool-gray , 200), 5%) !important;
    }
    .react-datepicker__header {
        @extend .bg-primary-100
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: map-get($cool-primary , 500);
    }
    .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
        border-color: map-get($cool-primary , 300);
    }
    .react-datepicker__navigation-icon {
        top: 5px;
    }
    .react-datepicker__day {
        @extend .text-gray-800
    };
    .react-datepicker__day--today {
        @extend .text-gray-850
    };
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        @extend .text-gray-800;
        @extend .fw-semibold;
    }
    .react-datepicker__day--selected {
        background-color: map-get($cool-primary , 500);
        color: white;
    }
}

//slider
.switches-container {
    input, label {
        visibility: hidden;
        position: absolute;
        top: 0;
    }
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
        box-shadow: none;
    }
}

//toast
#toast-container {
    .toast {
        box-shadow: none;
        border: none;
        &.toast-success {
            background-color: rgba(map-get($cool-primary, 600),0.8);
        }
        &.toast-warning {
            background-color: rgba(map-get($cool-orange, 400),0.7);
        }
        &.toast-error {
            background-color: rgba(map-get($cool-red, 400),0.7);
        }
        .toast-message {
            padding: 6px 2px;
            font-size: 20px;
        }
        &:hover {
            box-shadow: none;
        }
    }
}

.pointer {
    cursor: pointer;
}

.cur-auto {
    cursor: auto;
}

.no-event {
    pointer-events: none;
    cursor: auto;
}

.no-decor {
    &, &:hover {
        text-decoration: none;
    }
}

.inherit {
    &:hover {
        color: inherit;
    }
}

.img-full {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lh-normal {
    line-height: 32px;
}
