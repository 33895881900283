@mixin custom-button-variant($color, $background, $hover-background) {
    color: $color;
    background: $background;
    border: none;
    box-shadow: none;
    &:hover {
      background: $hover-background;
      transition: all 0.3s ease;
    }
    &:active {
        background: $hover-background;
    }
    &:focus {
        color: $color;
        background: $background;
        border: none;
        box-shadow: none;
    }
    
}

@mixin custom-outline-button-variant($color, $background, $hover-background) {
    color:rgba( $color, 0.5);
    background: $background;
    border: 1px solid $hover-background;
    box-shadow: none;
    &:hover {
      color: $color;
      background: $hover-background;
      border-color: $hover-background;
      transition: all 0.3s ease;
    }
    &:active, &:focus {
        background: $hover-background;
        border-color: $hover-background;
    }
    &:focus {
        box-shadow: none;
    }
    .btn-check:focus + &,
    .btn-check:checked + &,
    .btn-check:active + &
    {   
        color: $color;
        background: $hover-background;
        border-color: $hover-background;
        box-shadow: none;
    }
}