@import "/src/assets/scss/abstract/abstract.scss";

$cool-gray: map-get($parent-colors , 'cool-gray');

.add-student-wrapper {
    .card {
        box-shadow: 0 -2px 4px rgba(map-get($cool-gray , 300), 0.2);
        .card-body {
            padding: 28px 36px;
        }
        .card-header {
            background-color: map-get($cool-gray , 60);
            @extend .ft-default;
            padding: 20px 0 17px 20px;
            color: map-get($cool-gray, 900);
            border-bottom: 1px solid map-get($cool-gray, 200);
        }
        .input-box {
            .input-group {
                .react-datepicker-wrapper {
                    width: auto;
                    flex-grow: 1;
                    input {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
}