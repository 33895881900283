@import "/src/assets/scss/abstract/abstract.scss";

.dashboard-wrapper {
    .content {
        .feature-card {
            @include border-radius(10px);
            color: white;
            padding: 28px 32px;
            @include parent-breakpoint('lt-md') {
                margin: 0 0 30px 0;
                height: auto !important;
            }
        }
    }
}