@import "/src/assets/scss/abstract/abstract.scss";

$gray: map-get($parent-colors , 'cool-gray');
$primary: map-get($parent-colors , 'cool-primary');

.sidebar-wrapper {
    width: 120px;
    background-color: white;
    position: fixed;
    z-index: 1000;
    min-height: calc(100vh - 110px);
    top: 110px;
    @include parent-breakpoint('lt-lg') {
        width: 0;
        overflow: hidden;
        box-shadow: -8px 15px 20px rgb(map-get($gray, 850), 0.7);
        min-height: calc(100vh - 80px);
        top: 80px;
        &.show {
            width: 82px;
        }
    }
    .sidebar-container {
        min-height: inherit;
        .sidebar-item {
            margin: 30px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                width: 32px;
            }
            .text {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s, opacity 0.5s ease;
                letter-spacing: 0.4px;
                padding: 2px 0 0 44px !important;
                min-width: 240px;
            }
        }
    }
    &.big {
        width: 260px;
        background: map-get($primary, 200);
        transition: all 0.3s ease;
        @include parent-breakpoint('lt-lg') {
            width: 230px;
        }
        .sidebar-container {
            .sidebar-item {
                justify-content:left;
                margin-left: 44px;
                @include parent-breakpoint('lt-lg') {
                    margin-left: 25px;
                }
                .text {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .logo {
        width: 120px;
        height: 110px;
        img {
            height: 65px;
        }
    }
}