@import "/src/assets/scss/abstract/abstract.scss";

$gray: map-get($parent-colors , 'cool-gray');

.header {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 1001;
    @include parent-breakpoint('lt-lg') {
        padding: 0 24px;
    }
    .logo-wrapper {
        .logo {
            width: 120px;
            background-color: white; 
            border-bottom: 1px solid rgba(map-get($gray, 400), 0.4);
            img {
                height: 65px;
            }
            @include parent-breakpoint('lt-lg') {
                width: auto;
                background-color: transparent;
                border-bottom: none;
               img {
                width: 24px;
                height: auto;
               }
            }
        }
        .hamburger {
            cursor: pointer;
            span {
                width: 20px;
                height: 2px;
                display: block;
                @extend .bg-gray-800;
                &:not(:last-child) {
                    margin: 0 0 3px 0;
                }
            }
        }
    }
    .other-box {
        margin: 0 60px;
        flex-grow: 1;
        @include parent-breakpoint('lt-lg') {
            margin: 0 0 0 24px;
        }
        .search-bar {
            margin: 30px 0 0 0;
            .input-box {
                img {
                    position: absolute;
                    left: 20px;
                    top: 20px;
                }
                input {
                    width: 410px;
                    height: 55px;
                    font-size: $font-small;
                    padding-left: 46px;
                    @extend .bg-gray-70;
                    @extend .border-gray-200;
                    @include border-radius(6px);
                    @include placeholder {
                        font-size: $font-small;
                        color: rgba(map-get($gray, 900), 0.5);
                    }
                }
            }
        }
        .search-icon-md {
            cursor: pointer;
            margin: 28px 0 0 30px;
            img {
                position: static;
                filter: brightness(0) invert(1);
                width: 20px;
            }
        }
    }
    .signin-box {
        margin: 30px 60px 0 0;
        @include parent-breakpoint('lt-lg') {
            margin: 16px 0 0 0;
        }
    }
    @include parent-breakpoint('gt-md') {
        height: 110px;
    }
}