
// DONT MODIFY

$parent-breakpoints: (
  xs: '(min-width: 0) and (max-width: 575px)',
 sm: '(min-width: 576px) and (max-width: 767px)',
 md: '(min-width: 768px) and (max-width: 991px)',
 lg: '(min-width: 992px) and (max-width: 1199px)',
 xl: '(min-width: 1200px)',
 lt-md: '(max-width: 767px)',
 lt-lg: '(max-width: 991px)',
 lt-xl: '(max-width: 1199px)',
 gt-xs: '(min-width: 576px)',
 gt-sm: '(min-width: 768px)',
 gt-md: '(min-width: 992px)',
 ) !default;

$parent-colors: (
    'cool-primary' : (
        100: #F2F7FF,
        200: #B7E5E6,
        300: #6ECACD,
        500: #30D0B6,
        550: #30D0B6,
        600: #2acaad,
        700: #4CBC9A,
        800: #00AAB3,
        900: #3AB9C1,
        920: #3A8B8C,
        950: #007B85
    ),
    'cool-green' : (
        400: #4BBB46,
        500: #1FBA34
    ),
    'cool-yellow' : (
        400: #F9CB61,
        500: #ffca3a
        
    ),
    'cool-orange' : (
        300: #FEC64F,
        400: #FFB507
    ),
    'cool-blue' : (
        100: #cee9fa,
        200: #9dd3f6,
        500: #0B92E8
    ),
    'cool-red' : (
        400: #f87171,
        500: #ef4444
    ),
    'cool-gray' : (
        50: #f8fbff,
        60: #f1f2fd,
        70: #fdfdfd,
        80: #FAFAFA,
        90: #f6f6f6,
        100: #f4f5ff,
        150: #eef1f5,
        180: #EEEEEE,
        200: #E9EDF0,
        250: #c9cfd4,
        280: #b3b4bd,
        300: #A9A9A9,
        350: #A2A2A2,
        400: #64748b,
        500: #999999,
        550: #A098AE,
        580: #949494,
        600: #707070,
        700: #6D6E70,
        750: #777777,
        800: #424B54,
        850: #383E42,
        870: #3E4954,
        900: #08134A,
        920: #333333,
        950: #111111,
        960: #212121,
        970: #2D3E50,
        980: #020723,
        990: #202020
    ),
    'status-color' : (
        active: #09D800,
        inactive: #008DED,
        cancel: #D80000
    )
) !default;

$cool-primary: map-get($parent-colors, 'cool-primary');
$cool-gray: map-get($parent-colors, 'cool-gray');
$cool-red: map-get($parent-colors, 'cool-red');

//Theme colors
$primary: map-get($cool-primary, 500);
$secondary: map-get($cool-gray, 500);
$danger: map-get($cool-red, 400);

//Others
$custom-red: #F51010;

//font-sizes
$font-huge:90px;
$font-big:50px;
$font-h1:40px;
$font-h2:36px;
$font-h3:30px;
$font-h4: 24px;
$font-h5: 22px;
$font-h6: 20px;
$font-base: 18px;
$font-normal:16px;
$font-small:14px;
$font-smaller:13px;
$font-tiny:12px;
