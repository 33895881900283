@import "/src/assets/scss/abstract/abstract.scss";

.growth-wrapper {
    .tabs-box {
        margin-bottom: 16px;
    }
    .tab-btn {
        background-color: white;
        min-width: 130px;
        height: 56px;
        color: map-get($cool-gray, 900);
        font-size: $font-h6;
        font-weight: 400;
        line-height: 56px;
        &:not(:last-child) {
            margin-right: 20px;
        }
        &:hover, &.active {
            background-color: map-get($cool-primary, 500);
            color: white;
        }
        &.sp-btn {
            &:hover, &.active {
                background-color: white;
                color: map-get($cool-gray, 900);
            }
            .circle {
                @include circle(24px);
                border: 2px solid map-get($cool-primary, 500);
                color: map-get($cool-primary, 500);
                font-size: 14px;
                @include parent-breakpoint('xl') {
                    margin-right: 14px;
                }
            }
        }
    }
    .tabs-content {
        .growth-inner-wrapper {
            position: relative;
            .btn-container {
                position: absolute;
                right: 0;
                top: -72px;
                margin-bottom: 14px;
            }
            .left-box, .right-box {
                min-height: 350px;
                @include border-radius(10px);
            }
            .left-box {
                .no-results {
                    padding: 20px 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: inherit;
                    flex-direction: column;
                    .desp-box {
                        width: 80%;
                        margin: auto;
                        text-align: center;
                        color: map-get($cool-gray, 750);
                        .title {
                            color: map-get($cool-gray, 950);
                            padding-bottom: 18px !important;
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
            .right-box {
                padding: 40px;
                .select-reward-box {
                    &:not(:last-child) {
                        margin-bottom: 28px;
                    }
                    .select-overlap-box {
                        display: block;
                    }
                }
            }
        }
        
    }
    .select-overlap-box {
        display: inline-block;
        margin-right: 20px;
        position: relative;
        top: 3px;
    }
    .reward-wrapper {
        .reward-box {
           padding: 58px 48px 58px 48px;;
            .reward-title {
                margin-bottom: 36px !important;
            }
            .reward-list {
                .reward-card {
                    background-color: white;
                    box-shadow: 0 14px 40px rgba(map-get($cool-gray, 900), 0.15);
                    border-radius: 10px;
                    margin: 0 24px 24px 0;
                    .edit {
                        padding: 12px 9px 0 0;
                        color: map-get($cool-primary, 300);
                    }
                    .subject {
                        display: inline-block;
                        min-width: 70px;
                        padding: 6px 8px;
                        color: white;
                        text-align: center;
                    }
                    .content {
                        text-align: center;
                        margin: 30px 0;
                        .logo {
                            width: 68px;
                            margin-bottom: 12px;
                        }
                    }
                    .bottom {
                        .title {
                            padding: 5px 0;
                            text-align: center;
                            color: white;
                            margin: 0 !important;
                        }
                        .points {
                            overflow: hidden;
                            padding: 12px;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
            }
        }
        
    }
    .badges-wrapper {
        .badges-box {
            padding: 58px 48px 58px 48px;
            .badges-card {
                position: relative;
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 10px;
                margin: 0 24px 24px 0;
                .img-box {
                    margin: 30px auto 0 auto;
                }
                .title {
                    color: white;
                    font-size: $font-h6;
                }
                .locked-box {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    &.fade-in {
                        visibility: visible;
                        opacity: 1;
                        transition: visibility 0s linear 0s, opacity 300ms;
                    }
                    &.fade-out {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s linear 300ms, opacity 300ms;
                    }
                }
            }
        }
    }
}

.create-reward-modal-wrapper, .create-badges-modal-wrapper, .growth-inner-wrapper {
    .select-reward-box {
        &:not(:last-child) {
            margin-bottom: 28px;
        }
        textarea {
            @extend .ft-default;
            @extend .bg-primary-100;
            border: 0;
            padding: 10px 20px 10px 20px;
            @extend .text-gray-900;
            @extend .text-h6;
        }
        .input-box {
            &.grey-box {
                .form-control {
                    border-radius: 10px;
                    height: 56px;
                    @extend .text-gray-900;
                    @extend .ft-default;
                    font-size: $font-h6;
                    padding: 0 20px;
                    background-color: map-get($cool-primary, 100) !important;
                    &:focus {
                        box-shadow: none
                    }
                    @include placeholder {
                        @extend .text-gray-900;
                    }
                }
            }
        }
    }
}